import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import Register from './Register';
import Payment from './Payment';
import Home from './Home';
import Auth from './Auth';

export default function MainPage() {
  return (
    <Switch>
      <Route exact path="/" component={Register} />
      <Route path="/login" component={Home} />
      <Route path="/payment" component={Payment} />
      <Route exact path="/auth/:token" component={Auth} />
      <Redirect to="/" />
    </Switch>
  );
}
