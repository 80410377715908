import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  #payment_container {
    color: #333;
    width: 60%;
    @media only screen and (max-width: 990px) {
      width: 80%;
    }
    max-width: 400px;
    text-align: center;
    .payment__header {
      color: #333;
    }
    .payment_content {
      color: #333;
      .payment_content__steps {
        text-align: center;
        .payment_content__step {
          color: #333;
          display: flex;
          flex-direction: column;
          span {
            font-size: 13px;
            color: #333;
          }
          h2 {
            font-weight: 500;
            font-size: 20px;
            color: #333;
            margin-bottom: 20px;
          }
          p {
            color: #333;
            font-size: 13px;
          }
          input {
            border: 1px solid;
          }
          button {
            font-size: 17px;
            background: #e50913;
            color: #fff;
            text-transform: uppercase;
          }
          &.payment_content__step:last-child {
            text-align: left;
          }
        }
      }
    }
  }
`;
