import React from 'react';

import { Button } from '@material-ui/core';

import Header from '../components/Header';

import { Container } from './styles';

function Register({ history }) {
  const onHandlePayment = () => {
    history.push('payment');
  };

  return (
    <>
      <Header path="register" />
      <Container>
        <div id="home_container">
          <div className="home__header">
            <form className="d-flex flex-column text-center">
              <h2>
                100% diversão. <br />
                R$0,20 nos primeiros 30 dias.
              </h2>
              <strong>Assista onde quiser. Cancele quando quiser.</strong>
              <p>
                Pronto para assistir? Informe seu email para criar ou reiniciar
                sua assinatura.
              </p>
              <div className="align-items-center d-flex justify-content-between">
                <Button
                  onClick={() => {
                    onHandlePayment();
                  }}
                  variant="contained"
                  color="primary"
                  type="button"
                >
                  QUERO ASSINAR
                </Button>
              </div>
              <p>Esta oferta está disponível somente para não assinantes.</p>
            </form>
          </div>
          <div className="home__section">
            <div className="home__content">
              <div className="mb-4 mt-4">
                <h3>Meetings</h3>
                <p>
                  Persistent collaboration spaces, for team online meetings and
                  customer presentations, with SSO, HD video playback, digital
                  whiteboard, shared notes, chat and more.
                </p>
              </div>
              <div className="m-4">
                <img src="/assets/images/meetings.png" alt="Kaltuflix" />
              </div>
            </div>
          </div>
          <div className="home__section">
            <div className="home__content">
              <div className="m-4">
                <img src="/assets/images/communicate.png" alt="Kaltuflix" />
              </div>
              <div className="mb-4 mt-4">
                <h3>Communicate Your Way</h3>
                <p>
                  One single platform that supports a variety of communication
                  styles and use cases, whether synchronous or a-synchronous.
                </p>
              </div>
            </div>
          </div>
          <div className="home__section">
            <div className="home__content">
              <div className="mb-4 mt-4">
                <h3>Any Meeting Size and Type</h3>
                <p>
                  From 1:1, to 100,000, to unlimited. Easily launch engaging
                  online meetings, webinars and virtual classrooms, all the way
                  to large scale townhalls and international conferences, with
                  advanced analytics from live to VOD.
                </p>
              </div>
              <div className="m-4">
                <img
                  src="/assets/images/meeting-size-and-type.png"
                  alt="Kaltuflix"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Register;
