import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, CircularProgress } from '@material-ui/core';

import axios from 'axios';
import Header from '../components/Header';
import { Container } from './styles';
import { signInSuccess } from '../../../store/modules/auth/actions';
import { normalizeNumber, normalizeDate } from './components/utils/normalize';

function Payment() {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  async function onSubmitPayment() {
    setLoading(true);
    setTimeout(() => {}, 3000);
    setLoading(false);
  }

  const dispatch = useDispatch();
  const [values, setValues] = useState({
    email: '',
    password: '',
    nome: '',
    sobrenome: '',
    cardNumber: '',
    cardDate: '',
    cardCvv: '',
  });
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    nome: '',
    sobrenome: '',
    cardNumber: '',
    cardDate: '',
    cardCvv: '',
  });

  async function handleSubmit(e) {
    e.preventDefault();
    setErrors({
      email: '',
      password: '',
      nome: '',
      sobrenome: '',
      cardNumber: '',
      cardDate: '',
      cardCvv: '',
    });
    const lock_errors = {
      email: '',
      password: '',
      nome: '',
      sobrenome: '',
      cardNumber: '',
      cardDate: '',
      cardCvv: '',
    };
    let count = 0;
    if (values.nome === '') {
      lock_errors.nome = 'Preencha seu nome';
      count += 1;
    }
    if (values.sobrenome === '') {
      lock_errors.sobrenome = 'Preencha seu sobrenome';
      count += 1;
    }
    if (values.cardNumber === '') {
      lock_errors.cardNumber = 'Preencha o número do seu cartão';
      count += 1;
    }
    if (values.cardDate === '') {
      lock_errors.cardDate = 'Preencha a data de validade do seu cartão';
      count += 1;
    }
    if (values.cardCvv === '') {
      lock_errors.cardCvv = 'Preencha o código de segurança do seu cartão';
      count += 1;
    }
    if (count > 0) {
      return setErrors(lock_errors);
    }
    setLoading(true);
    try {
      setTimeout(() => {}, 2000);
      const { data } = await axios.post('https://flix-api.tupi.io/users', {
        email: values.email,
        password: values.password,
      });

      dispatch(
        signInSuccess(data.token, {
          customer_name: 'João da silva',
        })
      );
    } catch (err) {}
    return setLoading(false);
  }

  return (
    <>
      <Header path="payment" />
      <Container>
        <div id="payment_container">
          <div className="payment_content">
            <div className="payment_content__steps">
              {step === 1 && (
                <div className="payment_content__step">
                  <span>PASSO 1 de 2</span>
                  <h2>Termine de configurar sua conta.</h2>
                  <p>
                    A Kaltuflix é personalizada para você. Crie uma conta para
                    assistir à Kaltuflix em qualquer aparelho quando você
                    quiser.
                  </p>
                  <form className="d-flex flex-column">
                    <TextField
                      label="Email"
                      type="mail"
                      value={values.email}
                      onChange={(e) => {
                        setErrors({
                          email: '',
                        });
                        setValues({ ...values, email: e.target.value });
                      }}
                      error={errors.email !== ''}
                      disabled={loading}
                      variant="filled"
                      className="mb-2"
                    />
                    <TextField
                      label="Senha"
                      type="password"
                      value={values.password}
                      onChange={(e) => {
                        setErrors({
                          password: '',
                        });
                        setValues({ ...values, password: e.target.value });
                      }}
                      error={errors.password !== ''}
                      disabled={loading}
                      variant="filled"
                      className="mb-2"
                    />
                  </form>
                  <button
                    onClick={() => {
                      if (values.password === '') {
                        setErrors({
                          password: 'Digite sua senhe',
                        });
                        return false;
                      }
                      setStep(2);
                    }}
                    className="btn"
                  >
                    Continuar
                  </button>
                </div>
              )}
              {step === 2 && (
                <div className="payment_content__step">
                  <span>PASSO 1 de 2</span>
                  <h2>Informe seus dados de pagamento.</h2>
                  <p>
                    Sua assinatura começa assim que você configurar o pagamento.
                    Sem compromisso.
                  </p>
                  <p>Cancele online quando quiser.</p>
                  <button onClick={() => setStep(3)} className="btn">
                    Continuar
                  </button>
                </div>
              )}
              {step === 3 && (
                <div className="payment_content__step">
                  <span>PASSO 2 de 2</span>
                  <h2>Informe os dados do seu cartão de crédito ou débito</h2>
                  <form onSubmit={handleSubmit} className="d-flex flex-column">
                    <TextField
                      label="Nome"
                      type="text"
                      value={values.nome}
                      onChange={(e) => {
                        setErrors({
                          nome: '',
                        });
                        setValues({ ...values, nome: e.target.value });
                      }}
                      error={errors.nome !== ''}
                      disabled={loading}
                      variant="filled"
                      className="mb-2"
                    />
                    <TextField
                      label="Sobrenome"
                      type="text"
                      value={values.sobrenome}
                      onChange={(e) => {
                        setErrors({
                          sobrenome: '',
                        });
                        setValues({ ...values, sobrenome: e.target.value });
                      }}
                      error={errors.sobrenome !== ''}
                      disabled={loading}
                      variant="filled"
                      className="mb-2"
                    />
                    <TextField
                      label="Número do cartão"
                      type="text"
                      value={values.cardNumber}
                      onChange={(e) => {
                        setErrors({
                          cardNumber: '',
                        });
                        setValues({
                          ...values,
                          cardNumber: normalizeNumber(e.target.value),
                        });
                      }}
                      error={errors.cardNumber !== ''}
                      disabled={loading}
                      variant="filled"
                      className="mb-2"
                    />
                    <TextField
                      label="Data de validade (MM/AA)"
                      type="text"
                      value={values.cardDate}
                      onChange={(e) => {
                        setErrors({
                          cardDate: '',
                        });
                        setValues({
                          ...values,
                          cardDate: normalizeDate(e.target.value),
                        });
                      }}
                      inputProps={{ maxLength: 5 }}
                      error={errors.cardDate !== ''}
                      disabled={loading}
                      variant="filled"
                      className="mb-2"
                    />
                    <TextField
                      label="Código de segurança (CVV)"
                      type="text"
                      value={values.cardCvv}
                      onChange={(e) => {
                        setErrors({
                          cardCvv: '',
                        });
                        setValues({
                          ...values,
                          cardCvv: normalizeNumber(e.target.value),
                        });
                      }}
                      inputProps={{ maxLength: 4 }}
                      error={errors.cardCvv !== ''}
                      disabled={loading}
                      variant="filled"
                      className="mb-2"
                    />
                    <button
                      type="submit"
                      className="btn mt-2 mb-4"
                      disabled={loading}
                      onClick={() => onSubmitPayment()}
                    >
                      {loading ? (
                        <CircularProgress
                          style={{ color: '#fff', fontSize: '16px' }}
                        />
                      ) : (
                        'Iniciar assinatura'
                      )}
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Payment;
