import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

function Header(props) {
  return (
    <Container className={props.path === 'payment' ? 'payment_header' : ''}>
      <div className="d-flex align-items-center justify-content-between">
        <Link to="/">
          <img src="/assets/images/logo.png" alt="Kaltuflix" className="mr-3" />
        </Link>
        {props.path === 'register' ? (
          <Link className="action" to="/login">
            Entrar
          </Link>
        ) : (
          <Link className="action" to="/logout">
            Sair
          </Link>
        )}
      </div>
    </Container>
  );
}

export default Header;
