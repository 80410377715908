import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  top: 0px;
  padding: 30px 3.5rem;
  &.payment_header {
    border-bottom: 1px solid #e6e6e6;
  }
  img {
    width: 100%;
    max-width: 165px;
  }
  a.action {
    background-color: #e50914;
    line-height: normal;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 1rem;
    color: #fff;
    border-radius: 5px;
  }
`;
