import React from 'react';
import { useDispatch } from 'react-redux';
import { signOut } from '../../../../store/modules/auth/actions';
import { Container } from './styles';

function Header() {
  const dispatch = useDispatch();
  return (
    <Container>
      <div className="d-flex align-items-center justify-content-between">
        <img src="/assets/images/logo.png" alt="Kaltuflix" className="mr-3" />
        <button
          onClick={() => {
            dispatch(signOut());
          }}
        >
          Sair
        </button>
      </div>
    </Container>
  );
}

export default Header;
