import styled from 'styled-components';

export const Container = styled.div`
  background-image: url(/assets/images/home_background.jpg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  @media only screen and (max-width: 990px) {
    background-size: auto 100%;
  }
  padding: 250px 0;
  border-bottom: 8px solid #222;
  display: flex;
  align-items: center;
  justify-content: center;
  div#auth_container {
    width: 100%;
    max-width: 450px;
    @media only screen and (max-width: 990px) {
      width: 90%;
      padding: 40px 25px;
    }
    padding: 40px 55px;
    background: rgba(0, 0, 0, 0.5);
    h2 {
      color: #fff;
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 28px;
    }
    form {
      .auth_container__register {
        font-size: 16px;
        color: #737373;
        a {
          color: #fff;
          font-size: 16px;
          margin: 0px 0px 0px 10px;
        }
      }
    }
    button {
      background: #e50914 !important;
      padding: 15px 0;
    }
  }
`;
