import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  div#auth_container {
    width: 100%;
    max-width: 400px;
    background-image: url('https://assets.nflxext.com/ffe/siteui/vlv3/d49b6ea4-5a64-4996-a004-9d4d4f32839a/79dff55c-656a-4eb3-b1c7-db9ffc953aed/BR-pt-20200817-popsignuptwoweeks-perspective_alpha_website_small.jpg');
    color: #3757a1;
    padding: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 50px;
    }
  }
`;
