import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { signInSuccess } from '../../../store/modules/auth/actions';

import { Container } from './styles';
import Header from '../components/Header';

function Home() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });
  const dispatch = useDispatch();

  const [msg, setMsg] = useState({ type: '', value: '' });

  async function handleSubmit(e) {
    e.preventDefault();
    setMsg({ type: '', value: '' });
    const lock_error = {
      email: '',
      password: '',
    };

    if (email.indexOf('@') === -1) {
      lock_error.email = 'Email inválido';
    } else if (email.split('@')[1].indexOf('.') === -1) {
      lock_error.email = 'Email inválido';
    }
    if (lock_error.email !== '' || lock_error.password !== '') {
      setErrors(lock_error);
      return;
    }
    setLoading(true);
    try {
      setTimeout(() => {}, 2000);
      const { data } = await axios.post(
        'https://flix-api.tupi.io/auth/sign-in',
        {
          email,
          password,
        }
      );

      dispatch(
        signInSuccess(data.token, {
          customer_name: 'João da silva',
        })
      );
    } catch (err) {
      setMsg({
        type: 'error',
        value: 'Login e senha inválido',
      });
    }
    setLoading(false);
  }
  return (
    <>
      <Header path="login" />
      <Container>
        <div id="auth_container">
          <div className="d-flex  flex-column">
            <h2>Entrar</h2>
            <form onSubmit={handleSubmit} className="d-flex flex-column">
              {msg.value !== '' && (
                <Alert severity={msg.type} className="mb-3">
                  {msg.value}
                </Alert>
              )}
              <TextField
                label="Email"
                type="email"
                value={email}
                onChange={(e) => {
                  setErrors({
                    email: '',
                    password: '',
                  });
                  setEmail(e.target.value);
                }}
                variant="filled"
                className="mb-2"
                error={errors.email !== ''}
              />
              <TextField
                label="Senha"
                type="password"
                value={password}
                onChange={(e) => {
                  setErrors({
                    email: '',
                    password: '',
                  });
                  setPassword(e.target.value);
                }}
                variant="filled"
                className="mb-2"
                error={errors.password !== ''}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="mt-2 mb-4"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress
                    style={{ color: '#fff', fontSize: '16px' }}
                  />
                ) : (
                  'Entrar'
                )}
              </Button>
              <div className="align-items-center d-flex auth_container__register">
                Novo por aqui? <Link to="/sign-up">Assine agora.</Link>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Home;
