import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  div#home_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .home__header {
      background-image: url('/assets/images/home_background.jpg');
      padding: 250px 0 120px;
      border-bottom: 8px solid #222;
      form {
        h2 {
          color: #fff;
          font-size: 4rem;
          font-weight: 700;
          line-height: 70.4px;
          max-width: 800px;
          margin: auto;
          @media only screen and (max-width: 990px) {
            font-size: 3rem;
            line-height: 1;
          }
        }
        strong {
          font-size: 26px;
          font-weight: 300;
          margin-top: 20px;
          margin-bottom: 25px;
        }
        p {
          font-size: 19.2px;
          font-weight: 400;
        }
        label {
          display: none;
        }
        > div {
          width: fit-content;
          margin: auto;
          margin-bottom: 15px;
          @media only screen and (max-width: 990px) {
            flex-direction: column;
            width: 90%;
          }
          > div {
            @media only screen and (max-width: 990px) {
              width: 100%;
            }
            display: flex;
            margin: 0 !important;
            > div {
              margin: 0 !important;
            }
            input {
              width: 500px;
              @media only screen and (max-width: 990px) {
                width: 100%;
              }
              height: 70px;
              background: #fff;
              padding: 6px 20px;
            }
          }
          button {
            background: #e50914 !important;
            width: 355.422px;
            min-height: 83px;
            min-width: 74px;
            border-radius: 0;
            font-size: 1.25rem;
          }
        }
      }
    }
    .home__section {
      padding: 70px 45px;
      @media only screen and (max-width: 990px) {
        padding: 20px 20px;
      }
      border-bottom: 8px solid #222;
      .home__content {
        @media only screen and (max-width: 990px) {
          width: 90%;
          flex-direction: column;
        }
        max-width: 1100px;
        margin: auto;
        display: flex;
        align-items: center;
      }
    }
    h3 {
      font-size: 3.125rem;
      line-height: 1.1;
      margin-bottom: 0.5rem;
      @media only screen and (max-width: 990px) {
        font-size: 2rem;
      }
      & + p {
        font-size: 1.625rem;
        font-weight: 400;
        @media only screen and (max-width: 990px) {
          font-size: 14px;
        }
      }
    }
    img {
      @media only screen and (max-width: 990px) {
        width: 100%;
      }
      width: 400px;
    }
    a {
      display: block;
      margin-top: 15px;
      text-align: center;
    }
  }
`;
