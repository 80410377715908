import { takeLatest, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { signInSuccess } from './actions';

export function* signIn({ payload }) {
  try {
    const { token } = payload;
    const customer_name = 'João da silva';

    yield put(
      signInSuccess(token, {
        customer_name,
      })
    );
  } catch (err) {
    toast.error('Falha na autenticação, verifique seus dados');
  }
}

export function* setTokenSignIn() {}

export function signOutSaga() {}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_IN_SUCCESS', setTokenSignIn),
  takeLatest('@auth/SIGN_OUT', signOutSaga),
]);
