import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import Browse from './Browse';

export default function MainPage() {
  return (
    <Switch>
      <Route exact path="/browse" component={Browse} />
      <Redirect to="/browse" />
    </Switch>
  );
}
