import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import axios from 'axios';

import { useSpring, animated } from 'react-spring/web.cjs';

import { Container } from './styles';

import Header from '../components/Header';

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

function Browse() {
  const [open, setOpen] = useState(false);
  const [videoPlay, setVideoPlay] = useState(false);
  const [video, setVideo] = useState(null);
  const [playlists, setPlaylists] = useState([]);

  const [width, setWidth] = useState(window.innerWidth);
  const resizeWindow = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    async function loadVideos() {
      try {
        const { data } = await axios.get('https://flix-api.tupi.io/videos');
        setPlaylists(data);
      } catch (error) {
        window.location.href = '/browse';
      }
    }
    loadVideos();
  }, []);

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  const videoFeatured = {
    image: '/assets/video/destaque.jpg',
    name: 'Ed sheeran - Perfect',
    description:
      'A música “Perfect”, de Ed Sheran, lançada no final do ano passado, tem sido uma das mais tocadas e regravadas dos últimos meses! Esse é o single “mais especial” para o cantor. Pra quem não sabe, a canção foi escrita para a namorada de Ed, Cherry Seaborn. Bora ficar por dentro das curiosidades que envolvem esse grande sucesso? Vou te contar, passo a passo, tudo o que a música quer transmitir.',
    entry_id: '0_hvgc5v5l',
  };

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));

  const classes = useStyles();

  const onHandleAboutVideo = (v) => {
    setOpen(true);
    setVideo(v);
  };
  const onHandlePlayerVideo = (v) => {
    setOpen(false);
    setVideo(v);
    setVideoPlay(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Header />
      <Container>
        {videoPlay && (
          <div id="kalturaWrapper">
            <button onClick={() => setVideoPlay(false)} className="close">
              X
            </button>
            <iframe
              src={`https://cdnapisec.kaltura.com/p/2601552/embedPlaykitJs/uiconf_id/46258051?iframeembed=true&entry_id=${video.entry_id}`}
              style={{
                overflow: 'hidden',
                width: '100%',
                height: '100vh',
                zIndex: 2,
                position: 'relative',
              }}
              title="Kaltuflix"
              // allowFullScreen
              frameBorder="0"
            />
          </div>
        )}
        <div className="video__featured">
          <div
            className="video__featured_content"
            style={{ backgroundImage: `url('${videoFeatured.image}')` }}
          >
            <h2>{videoFeatured.name}</h2>
            <div>
              <button onClick={() => onHandlePlayerVideo(videoFeatured)}>
                Assistir
              </button>
              <button onClick={() => onHandleAboutVideo(videoFeatured)}>
                Mais informações
              </button>
            </div>
          </div>
        </div>
        <div className="videos__playlists">
          {playlists.map((e, i) => {
            return (
              <div className="videos__playlist" key={i}>
                <h4>{e.name}</h4>
                <div className="videos__playlist_content">
                  <Swiper
                    navigation
                    grabCursor={false}
                    draggable={false}
                    loopAdditionalSlides={
                      width >= 1378
                        ? 4
                        : width >= 998
                        ? 3
                        : width >= 625
                        ? 2
                        : 2
                    }
                    breakpoints={{
                      1378: {
                        slidesPerView: 5,
                        slidesPerGroup: 5,
                      },
                      998: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                      },
                      625: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                      },
                      0: {
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                      },
                    }}
                    spaceBetween={5}
                    preventClicksPropagation
                    preventClicks
                    scrollbar={{ draggable: false, hide: true }}
                    slideToClickedSlide={false}
                  >
                    {e.videos.map((elem, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <button onClick={() => onHandleAboutVideo(elem)}>
                            <img src={elem.image} alt={elem.name} />
                            <p>{elem.name}</p>
                          </button>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            );
          })}
        </div>
        {video && (
          <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div
                className="modal show"
                style={{
                  backgroundImage: `url('${video.image}')`,
                }}
              >
                <div className="modal__container">
                  <h1 className="modal__title">{video.name}</h1>
                  <p className="modal__overview">{video.description}</p>
                  <button
                    onClick={() => onHandlePlayerVideo(video)}
                    className="modal__btn modal__btn--red"
                  >
                    Assistir
                  </button>
                </div>
              </div>
            </Fade>
          </Modal>
        )}
      </Container>
    </>
  );
}

export default Browse;
